import React from "react"
import { Link } from "gatsby"

export default function Header() {
  return (
    <header>
        <div className="logo">
            <Link to="/">Tarek Alakmeh</Link>
        </div>
        <nav>
            <Link to="/">Computer Science</Link>
            <Link to="/">Photography</Link>
            <Link to="/">Personal</Link>
            <Link to="/">Literature</Link>
            <Link to="/">Music</Link>
        </nav>
    </header>
  )
}