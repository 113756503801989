import React from "react"
import profilePic from '../assets/profile-pic-tarek.jpeg'


export default function Bio() {
  return (
    <div className="bio">
        <img 
            src={profilePic} 
            alt={`Tarek Alakmeh`} 
            className="profilePic"
        />
        <p>
          Personal blog by{' '} Tarek Alakmeh.{' '}
          Exploring arts and science.
        </p>
    </div>
  )
}