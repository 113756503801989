import React from "react"
import { Link, graphql } from "gatsby"
import Header from "../components/header"
import Bio from "../components/bio"
import Layout from "../components/layout"

export default function Home({ data }) {
  return (
    <div className="container">
      <Layout>
        <Bio />
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <article key={node.id}>
              <Link to={node.fields.slug} >
                <h3>{node.frontmatter.title}</h3>
                <span><i>{node.frontmatter.date} — {node.timeToRead} min</i></span>
                <p>{node.frontmatter.description}</p>
              </Link>
            </article>
          ))}
      </Layout>
      <p className="footerLine">New post every full moon</p>
    </div>
  )
}

export const query = graphql`
query MyQuery {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    edges {
      node {
        id
        timeToRead
        frontmatter {
          title
          description
          spoiler
          date(formatString: "DD.MM.YY")
        }
        fields {
          slug
        }
      }
    }
  }
}
`
